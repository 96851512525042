import React from "react";
import { inject, observer } from "mobx-react";
import { TopBar } from "../../components/TopBar";
import { MainTopBarMenu } from "../../components/MainTopBarMenu";
import { ReportPaginator } from "../../components/ReportPaginator";
import { ParentFilterPanel } from "../../components/filters/ParentFilterPanel";
import { Modal, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Layout } from "../../components/Layout";
import { BiNotepad } from "@react-icons/all-files/bi/BiNotepad";
import { BiXCircle } from "@react-icons/all-files/bi/BiXCircle";
import { BiDetail } from "@react-icons/all-files/bi/BiDetail";
import { RecordsNotFound } from "../../components/RecordsNotFound";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  BiCheckCircle,
  BiDollarCircle,
  BiLinkExternal,
  BiDotsVerticalRounded,
  BiSortAlt2,
  BiSend
} from "react-icons/bi";
import { NameAvatar } from "../../components/NameAvatar";
import { VendorsLinks } from "../../components/VendorsLinks";
import { RightPanel } from "../../components/RightPanel";
import { MoneyOutLinks } from "../../components/MoneyOutLinks";
import { MainBar } from "../../components/MainBar";
import { PayabliStorageManager } from "../../api/localStorageManager";
import { FileViewer } from "../../components/FileViewer"; 

@inject("reports", "global", "vendor")
@observer
class TransactionsReportMoneyOut extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      head: "",
      sortDirection: "desc",
      billIdToDelete: null,
      userPermissions: [],
      cancelModalIsOpen: false,
      transIdToCancel: null,
      paymentId: null,
      transIdToCapture: null,
      captureModalIsOpen: false,
      captureReceiptModalIsOpen: false,
      captureAmount: "0.00",
      flagItemAllColumn: true,
      batchNumber: null,
      showSendCardLinkModal: false,
      transId: "",
      typeTrx: -1,
    };
    this.getReportFromApi = this.getReportFromApi.bind(this);
    this.showHideFilters = this.showHideFilters.bind(this);
    this.exportFile = this.exportFile.bind(this);
    this.filter = this.filter.bind(this);
    this.selectAllColumns = this.selectAllColumns.bind(this);
    this.handleQuickFilter = this.handleQuickFilter.bind(this);
    this.handleShowColumn = this.handleShowColumn.bind(this);
    this.viewRecordDetails = this.viewRecordDetails.bind(this);
    this.cancelPayment = this.cancelPayment.bind(this);
    this.openCancelModal = this.openCancelModal.bind(this);
    this.closeCancelModal = this.closeCancelModal.bind(this);
    this.viewVendor = this.viewVendor.bind(this);
    this.captureTransaction = this.captureTransaction.bind(this);
    this.openCaptureModal = this.openCaptureModal.bind(this);
    this.closeCaptureModal = this.closeCaptureModal.bind(this);
    this.captureTransactionAction = this.captureTransactionAction.bind(this);
    this.closeCaptureReceiptModal = this.closeCaptureReceiptModal.bind(this);
    this.getNameReport = this.getNameReport.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.getBillsNumbers = this.getBillsNumbers.bind(this);
    this.sortBy = this.sortBy.bind(this);
  }

  clearFilters() {
    this.setState({ head: "" }, () => {
      this.props.reports.clearFilters();
      this.getReportFromApi();
    });
  }

  getNameReport() {
    return (
      <>
        {this.props.match.params.id && this.state.currentVendor && (
          <h6>
            Vendor - {this.state.currentVendor.Name1}{" "}
            {this.state.currentVendor.Name2}
          </h6>
        )}
        {this.props.match.params.billNumber && (
          <h6>
            Bill # {decodeURIComponent(this.props.match.params.billNumber)}
          </h6>
        )}
        {this.props.match.params.idBatch && (
          <div className="mr-3 inline">
            <h6 data-qaid="batchReportMoneyOutPage">{"Batch Payments"}</h6>
          </div>
        )}
        {!this.props.match.params.idBatch && (
          <div className="mr-3 inline">
            <h6 data-qaid="allTransactionsMoneyOutReportsPage">
              {this.state.head
                ? this.props.global.getMoneyOutTransStatus(
                    this.state.head,
                    true
                  )
                : "All Transactions"}
            </h6>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.props.global.protect(this.props.history);
    this.props.reports.setFrom(0);
    this.props.global.setLoading(true);
    this.props.reports.setHeaders({
      VendorName: { label: "Vendor Name", class: "", display: true },
      PaymentId: { label: "Payment Id", class: "", display: false },
      LotNumber: { label: "Lot #", class: "", display: false },
      externalPaypointID: {
        label: "External Paypoint ID",
        class: "",
        display: false,
      },
      BatchNumber: { label: "Batch #", class: "", display: false },
      VendorId: { label: "Vendor Id", class: "", display: false },
      CustomerVendorAccount: { label: "Account #", class: "", display: true },
      VendorNumber: { label: "Vendor #", class: "", display: false },
      Ein: { label: "Tax ID", class: "", display: false },
      VendorEmail: { label: "Email", class: "", display: false },
      VendorPhone: { label: "Phone", class: "", display: false },
      BillingAddress: { label: "Billing Address", class: "", display: false },
      RemitAddress: { label: "Remit Address", class: "", display: false },
      BillNumber: { label: "Bill Number", class: "", display: true },
      Date: { label: "Date", class: "", display: true },
      Time: {
        label: "Time (" + this.props.global.getTimeZone("v1") + ")",
        class: "",
        display: true,
      },
      Type: { label: "Type", class: "text-center", display: true },
      Status: { label: "Status", class: "", display: true },
      LastEvent: { label: "Last Event", display: false },
      NetAmount: { label: "Amount", class: "text-right", display: true },
      FeeAmount: { label: "Fee", class: "text-right", display: false },
      TotalAmount: {
        label: "Total Amount",
        class: "text-right",
        display: false,
      },
      TransId: { label: "Trans. Id", class: "", display: false },
      Source: { label: "Source", class: "", display: false },
      Notes: { label: "Notes", class: "text-center", display: true },
      LastUpdatedOn: { label: "Last Updated On", class: "", display: false },
    });
    this.props.reports.setRecords([]);
    if (this.props.match.params.id) {
      this.props.vendor
        .getVendorFromApi(this.props.match.params.id)
        .then((res) => {
          this.setState({ currentVendor: res.data });
          this.getReportFromApi({ currentVendor: res.data });
        })
        .catch((error) => {
          toast.error("Something is Wrong", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error-container",
          });
        });
    } else {
      this.setState({ currentVendor: null });
      if (this.props.match.params.billNumber) {
        this.setState({
          billNumber: decodeURIComponent(this.props.match.params.billNumber),
        });
      }
      if (this.props.match.params.idBatch) {
        this.setState({ batchId: this.props.match.params.idBatch });
      }
      this.getReportFromApi();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.props.reports.clearFilters();
  }

  selectAllColumns(e, containerId) {
    let menuContainer = document.querySelector(
      '[aria-labelledby="' + containerId + '"]'
    );
    let checkBoxes = menuContainer.getElementsByTagName("input");
    for (var i = 0; i < checkBoxes.length; i++) {
      if (
        checkBoxes[i].type.toLowerCase() === "checkbox" &&
        checkBoxes[i].checked === !e.target.checked
      ) {
        checkBoxes[i].click();
      }
    }
    this.setState({ flagItemAllColumn: false });
  }

  captureTransaction(transId, captureAmount) {
    this.setState({
      transIdToCapture: transId,
      captureAmount: captureAmount,
    });
    this.openCaptureModal();
  }

  sendCardLinkModal(transId, status) {
    const showSendCardLinkModal = this.state.showSendCardLinkModal;
    this.setState({
      transId: transId ?? '',
      typeTrx: status ?? -1,
      showSendCardLinkModal: !showSendCardLinkModal,
    });
  }

  closeCaptureReceiptModal() {
    this.setState({ captureReceiptModalIsOpen: false });
    this.getReportFromApi();
  }

  openCaptureModal() {
    this.setState({ captureModalIsOpen: true });
  }

  closeCaptureModal() {
    this.setState({ captureModalIsOpen: false });
  }

  captureTransactionAction() {
    let transIdToCapture = this.state.transIdToCapture;

    if (transIdToCapture) {
      this.props.global.setLoading(true);
      this.props.reports
        .captureTransactionOut(transIdToCapture)
        .then((result) => {
          this.setState({ captureModalIsOpen: false });
          this.props.global.setLoading(false);
          if (
            result.data.responseData.resultCode &&
            result.data.responseData.resultCode !== 1
          ) {
            let message = result.data.responseText
              ? "Sorry, the payment was " + result.data.responseText
              : "Sorry, we're having a problem capturing your payment.";
            toast.error(message, {
              position: toast.POSITION.BOTTOM_RIGHT,
              className: "toast-error-container",
            });
          } else {
            this.setState({
              paymentApproved: true,
              captureReceiptModalIsOpen: true,
              referenceId: result.data.responseData.referenceId
                ? result.data.responseData.referenceId
                : "-",
              paymentAuthCode: result.data.responseData.authCode
                ? result.data.responseData.authCode
                : "-",
            });
          }
        })
        .catch((error) => {
          this.props.global.setLoading(false);
          let errorMessage =
            error.response && error.response.data.responseText
              ? error.response.data.responseText
              : "Something is Wrong!";
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error-container",
          });
        });
    }
  }

  sendCardLinkAction() {
    const { transId, typeTrx } = this.state;
    if (!transId) {
      toast.error("Transaction Id is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error-container",
      });
      return;
    }

    if (typeTrx === -1) {
      toast.error("Transaction status is required!", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error-container",
      });
      return;
    }

    this.props.global.setLoading(true);
    try {
      this.props.reports.executeCaptureAndSendVCardLink(transId, typeTrx).then((res) => {
        this.props.global.setLoading(false);

        if (!res.isSuccess) {
          toast.error(res.message ?? "Something is Wrong!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error-container",
          });
          return;
        }

        toast.success(res.message ?? "VCard link sent successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success-container",
        });
      });
    } catch (error) {
      this.props.global.setLoading(false);
      const errorMessage = error.response?.data?.responseText
          ? error.response.data.responseText
          : "Something is Wrong!";
      toast.error(errorMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error-container",
      });
    }

    this.sendCardLinkModal();
  }

  viewVendor(path) {
    if (path) {
      this.props.global.setRightPanelOpen(false);
      this.props.history.push(path);
    }
  }

  openCancelModal() {
    this.setState({ cancelModalIsOpen: true });
  }

  closeCancelModal() {
    this.setState({ cancelModalIsOpen: false });
  }

  cancelPayment(id) {
    this.setState({ transIdToCancel: id }, () => {
      this.openCancelModal();
    });
  }

  cancelPaymentAction() {
    this.props.global.setLoading(true);
    this.props.reports
      .cancelTransactionMoneyOut(this.state.transIdToCancel)
      .then((res) => {
        this.getReportFromApi();
        toast.success("Payment canceled successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-success-container",
        });
        this.closeCancelModal();
      })
      .catch((error) => {
        let errorMessage =
          error.response && error.response.data.responseText
            ? error.response.data.responseText
            : "Something is Wrong!";
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error-container",
        });
        this.props.global.setLoading(false);
      });
  }

  filter(type, value) {
    this.props.global.setLoading(true);
    this.props.reports
      .filter(type, value, "payouts")
      .then((res) => {
        if (this.props.match.params.idBatch) {
          let tempBatchNumber =
            this.props.reports.records.length > 0
              ? this.props.reports.records[0].BatchNumber
              : null;
          this.setState({ batchNumber: tempBatchNumber });
        }
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
        let errorMessage =
          error.response && error.response.data.responseText
            ? error.response.data.responseText
            : "Something is Wrong!";
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error-container",
        });
      });
  }

  showHideFilters() {
    this.props.global.setFilterRightPanelOpen(true);
  }

  handleShowColumn(event) {
    this.props.reports.handleShowColumn(event);
    if (!this.state.flagItemAllColumn) {
      this.setState({ flagItemAllColumn: true });
    }
  }

  exportFile(format) {
    this.props.reports.exportFile("payouts", format, this.state.orgId);
  }

  getBillsNumbers(record) {
    let text = [];
    if (record) {
      record.forEach(function (item, index) {
        text.push(
          <>
            <span>
              Bill #{index + 1}: {item.invoiceNumber}
            </span>
            <br />
          </>
        );
      });
      return text;
    }
    return text;
  }

  viewRecordDetails(index, object) {
    if (
      object &&
      (this.props.global.isDrag() ||
        object.target.rel === "noreferrer" ||
        object.target.tagName.toLowerCase() === "svg" ||
        object.target.tagName.toLowerCase() === "a" ||
        object.target.tagName.toLowerCase() === "path" ||
        object.target.className === "papercheckblue" ||
        object.target.className === "fade modal show")
    ) {
      return;
    }
    if (object?.target?.id !== "actionsMenuButton") {
      let thisObj = this;
      if (
        thisObj.props.global.rightPanelOpen &&
        thisObj.props.reports.recordDetails !== index
      ) {
        thisObj.props.global.setRightPanelOpen(false);
        thisObj.props.reports.setRecordDetails(index);
        setTimeout(function () {
          thisObj.props.global.setRightPanelOpen(true);
        }, 500);
      } else {
        thisObj.props.reports.setRecordDetails(index);
        thisObj.props.global.setRightPanelOpen(true);
      }
    }
  }

  getMappingFields() {
    return {
      Date: "transactiondate",
      Status: "status",
      PaymentId: "paymentid",
      VendorNumber: "vendornumber",
      BatchNumber: "batchnumber",
      TotalAmount: "totalamount",
      FeeAmount: "feeamount",
      NetAmount: "netamount",
      PaypointLegalname: "paypointlegal",
      externalPaypointID: "paypointdba",
      VendorName: "vendorname",
      VendorId: "vendorid",
      ParentOrgName: "orgname",
      LastUpdatedOn: "lastupdatedon",
      LotNumber: "lotnumber",
      CustomerVendorAccount: "accountid",
      BillNumber: "invoicenumber",
      Type: "paymentmethod",
      Ein: "vendorein",
      VendorEmail: "vendoremail",
      VendorPhone: "vendorphone",
      Source: "source",
    };
  }
  middlwareFields(field) {
    const apiFields = this.getMappingFields();
    return apiFields[field];
  }
  sortBy(e, record) {
    this.setState(
      {
        sortBy: this.middlwareFields(record[0]) || "",
        sortDirection: this.state.sortDirection === "asc" ? "desc" : "asc",
      },
      () => {
        this.getReportFromApi();
      }
    );
  }
  getReportFromApi(currentVendor) {
    let tempFilterValue = {};
    if (this.state.sortBy) {
      tempFilterValue[
        "sortBy"
      ] = `${this.state.sortDirection}(${this.state.sortBy})`;
    }
    if (this.state.currentVendor || currentVendor) {
      tempFilterValue["vendorNumber(eq)"] = currentVendor.VendorNumber
        ? currentVendor.VendorNumber
        : this.state.currentVendor.VendorNumber;
    }
    if (this.props.match.params.billNumber) {
      tempFilterValue["billNumber(ct)"] = decodeURIComponent(
        this.props.match.params.billNumber
      );
    }
    if (this.props.match.params.idBatch) {
      tempFilterValue["batchId(eq)"] = this.props.match.params.idBatch;
    }
    this.filter(tempFilterValue);
  }

  handleQuickFilter(value, comparrison) {
    this.props.reports.clearFilters();
    if (comparrison) {
      this.filter(comparrison, value);
    } else {
      this.filter("status(eq)", value);
    }
    this.setState({ head: value });
  }

  render() {
    const recordDetail = this.props.reports.getRecordDetails;

    const summary = this.props.reports.summary;

    return (
      <Layout {...this.props}>
        <div>
          <Modal
            style={{ textAlign: "center" }}
            show={this.state.cancelModalIsOpen}
            onHide={this.closeCancelModal}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <BiXCircle className="icon-modal" />
              <h5 data-qaid="cancelPaymentModalMoneyOut">Cancel Payment</h5>
              <p className="small">
                Are you sure you want to cancel this payment?
              </p>
              <Button
                className="btn cancel-btn"
                variant="default"
                onClick={(e) => this.closeCancelModal()}
                data-qaid="closeButtonMoneyOut"
              >
                Close
              </Button>
              &nbsp;&nbsp;
              <Button
                className="btn"
                variant="danger"
                onClick={(e) => this.cancelPaymentAction()}
              >
                Cancel
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            style={{ textAlign: "center" }}
            show={this.state.captureModalIsOpen}
            onHide={this.closeCaptureModal}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <BiDollarCircle className="icon-modal" />
              <h5>Capture Payment</h5>
              <p className="small">
                Are you sure you want to capture this payment?
                <br />
                <br />
                <b>Transaction Id:</b>
                <br />
                {this.state.transIdToCapture}
              </p>

              <Button
                className="btn full-w"
                variant="primary"
                onClick={this.captureTransactionAction}
              >
                Capture
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            style={{ textAlign: "center" }}
            show={this.state.showSendCardLinkModal}
            onHide={() => this.sendCardLinkModal()}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <BiSend className="icon-modal" />
              <h5>Send VCard Link</h5>
              <p className="small">
                Are you sure you want to send VCard link?
                <br />
                <br />
                <b>Transaction Id:</b>
                <br />
                {this.state.transId}
              </p>
              <Button
                className="btn full-w"
                variant="primary"
                onClick={() => this.sendCardLinkAction()}
              >
                Send
              </Button>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.captureReceiptModalIsOpen}
            onHide={this.closeCaptureReceiptModal}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <div className="text-center mt-4">
                <b>Capture Receipt</b>
                <h2 className="mb-4 mt-4">
                  $
                  {this.props.global.numberWithCommas(
                    parseFloat(this.state.captureAmount).toFixed(2)
                  )}
                </h2>
                {this.state.paymentApproved ? (
                  <p>
                    <BiCheckCircle
                      style={{ fontSize: "28px", color: "rgb(136,201,82)" }}
                    />{" "}
                    Approved
                  </p>
                ) : (
                  <>
                    <p className="mb-2">
                      <BiCheckCircle
                        style={{
                          fontSize: "28px",
                          color: "rgb(255, 100, 82)",
                        }}
                      />{" "}
                      Declined
                    </p>
                    <p className="small-grey mt-0 mb-2">
                      {this.state.paymentResponseMessage}
                    </p>
                  </>
                )}
              </div>

              <div
                className="review-total mb-3"
                style={{ borderBottom: "none" }}
              >
                <div className="row mb-3 mt-3">
                  <div className="col-6 text-left">
                    <small>
                      Paid on{" "}
                      {this.props.global.stringDateFormat(
                        new Date().toString()
                      )}
                      ,{" "}
                      {this.props.global.stringTimeFormat(
                        new Date().toString()
                      )}
                    </small>
                  </div>
                </div>

                <div className="row">
                  <div className="col">Payment:</div>
                  <div className="col">
                    $
                    {this.props.global.numberWithCommas(
                      parseFloat(this.state.captureAmount).toFixed(2)
                    )}
                  </div>
                </div>

                <div className="top-border-grey mt-2 bottom-border-grey">
                  <div className="row total-amount">
                    <div className="col">Total Amount:</div>
                    <div className="col">
                      $
                      {this.props.global.numberWithCommas(
                        parseFloat(this.state.captureAmount).toFixed(2)
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <div className="row total-amount">
                    <div className="col-4">Reference Id: </div>
                    <div className="col-8">
                      {this.state.referenceId ? this.state.referenceId : ""}
                    </div>
                    {this.state.paymentApproved && (
                      <>
                        <div className="col-4">AuthCode: </div>
                        <div className="col-8">
                          {this.state.paymentAuthCode
                            ? this.state.paymentAuthCode
                            : ""}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <Button
                id="btnCloseReceipt"
                className="btn full-w btn-lg"
                onClick={this.closeCaptureReceiptModal}
              >
                Close
              </Button>
            </Modal.Body>
          </Modal>

          <RightPanel>
            <h5 className="header mb-3" data-qaid="transactionMoneyOutDetails">
              Transaction Details
            </h5>

            <div className="small mb-5">
              <div className="row">
                <div className="col-4">
                  <label className="header">
                    Date & Time{" "}
                    {" (" + this.props.global.getTimeZone("v1") + ")"}
                  </label>
                </div>
                <div className="col-8">
                  {recordDetail
                    ? this.props.global.stringDateFormat(recordDetail.CreatedAt)
                    : "-"}
                  -
                  {recordDetail
                    ? this.props.global.stringTimeFormat(recordDetail.CreatedAt)
                    : "-"}
                </div>
              </div>
              <div className="row">
                <div className="col-4">
                  <label className="header">
                    Last Updated{" "}
                    {" (" + this.props.global.getTimeZone("v1") + ")"}
                  </label>
                </div>
                <div className="col-8">
                  {recordDetail
                    ? this.props.global.stringDateFormat(
                        recordDetail.LastUpdated
                      )
                    : "-"}
                  -
                  {recordDetail
                    ? this.props.global.stringTimeFormat(
                        recordDetail.LastUpdated
                      )
                    : "-"}
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <label className="header">Type</label>
                </div>
                <div className="col-8">
                  {recordDetail && recordDetail.PaymentMethod
                    ? this.props.global.capitalizeFirstLetter(
                        recordDetail.PaymentMethod
                      )
                    : "-"}
                </div>
              </div>

              <div className="row">
                <div className="col-4">
                  <label className="header">Status</label>
                </div>
                <div className="col-8">
                  { this.props.global.getMoneyOutTransStatus(
                        recordDetail?.Status
                      )}
                </div>
              </div>

              <div className="row mb-4">
                {recordDetail && recordDetail.Comments && (
                  <div className="col-12">
                    <label className="header">Notes:</label>
                    <p>{recordDetail.Comments}</p>
                  </div>
                )}

                {recordDetail && recordDetail.Bills && (
                  <div className="col-12 mb-3 mt-3">
                    {recordDetail.Bills.map((record, i) => (
                      <div className="row mb-1" key={i}>
                        <div className="col-6">
                          <b>Bill Number {i + 1}</b>
                        </div>
                        <div className="col-6 text-right">
                          <a
                            href={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/report/bills/bill/" +
                              record.invoiceNumber
                            }
                          >
                            {record.invoiceNumber}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                <div className="col-12">
                  <div className="row mb-1">
                    <div className="col-4">
                      <b>Amount</b>
                    </div>
                    <div className="col-8 text-right">
                      {recordDetail && recordDetail.NetAmount
                        ? this.props.global.numberWithCommasNegative(
                            recordDetail.NetAmount.toFixed(2)
                          )
                        : "-"}
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-4">
                      <b>Fee</b>
                    </div>
                    <div className="col-8 text-right">
                      {recordDetail && recordDetail.FeeAmount
                        ? this.props.global.numberWithCommasNegative(
                            recordDetail.FeeAmount.toFixed(2)
                          )
                        : "$0.00"}
                    </div>
                  </div>

                  <div className="row mb-1">
                    <div className="col-4">
                      <b>Total</b>
                    </div>
                    <div className="col-8 text-right">
                      <b>
                        {recordDetail && recordDetail.TotalAmount
                          ? this.props.global.numberWithCommasNegative(
                              recordDetail.TotalAmount.toFixed(2)
                            )
                          : "-"}
                      </b>
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="header mb-3">Payment Information</h5>

              <div className="card-v2 mb-3" style={{ padding: "3em" }}>
                <div className="right-panel-card mb-4">
                  {recordDetail?.PaymentMethod?.toLowerCase() === "vcard" ||
                  recordDetail?.PaymentMethod?.toLowerCase() === "ach" ||
                  recordDetail?.PaymentMethod?.toLowerCase() === "check" ? (
                    <span style={{ verticalAlign: "bottom" }}>
                      {this.props.global.getGlobalImg(
                        recordDetail?.PaymentMethod?.toLowerCase(),
                        "45px"
                      )}
                    </span>
                  ) : (
                    <span style={{ fontSize: "20px", paddingTop: "4px" }}>
                      {this.props.global.capitalizeFirstLetter(
                        recordDetail?.PaymentMethod
                      )}
                    </span>
                  )}
                </div>

                <div className="row mb-2">
                  <div className="col-7">
                    <label className="grey">Payment Status</label>
                    <h5 style={{ fontWeight: 500, fontSize: "14px" }}>
                      {recordDetail && recordDetail.PaymentStatus
                        ? recordDetail.PaymentStatus
                        : "-"}
                    </h5>
                  </div>

                  <div className="col-5">
                    <label className="grey">Date</label>
                    <h5 style={{ fontWeight: 500, fontSize: "14px" }}>
                      {recordDetail
                        ? this.props.global.stringDateFormat(
                            recordDetail.CreatedAt
                          )
                        : "-"}
                    </h5>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <label className="grey">Paypoint Name</label>
                    <h6 style={{ fontWeight: 500, fontSize: "15px" }}>
                      {recordDetail && recordDetail.PaypointDbaname
                        ? recordDetail.PaypointDbaname
                        : "-"}
                    </h6>
                  </div>
                </div>
              </div>

              <div className="text-center mb-4">
                <div className="btn-group" role="group">
                  {(recordDetail && (recordDetail.Status === 1 || recordDetail.Status === 11))&& (
                    <button
                      onClick={(e) =>
                        this.cancelPayment(recordDetail.PaymentId)
                      }
                      type="button"
                      className="btn btn-outline-primary"
                    >
                      <BiXCircle /> Cancel Payment
                    </button>
                  )}
                  <button
                    onClick={(e) =>
                      this.viewVendor(
                        "/" +
                          this.props.global.getURLEntry() +
                          "/vendor/" +
                          (recordDetail && recordDetail.Vendor
                            ? recordDetail.Vendor.VendorId
                            : "")
                      )
                    }
                    type="button"
                    className="btn btn-outline-primary"
                  >
                    <BiLinkExternal /> View Vendor
                  </button>
                  {recordDetail && recordDetail.CheckData && (
                    <FileViewer fileData={recordDetail.CheckData} fileType={recordDetail.CheckData.ftype} isQuickView={true} />
                  )}
                </div>
              </div>

              <h5 className="header mb-3">Vendor Information</h5>
              <div className="row mb-4">
                <div className="col-4">
                  <label className="header">Vendor Name</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.Name1
                    ? this.props.global.capitalizeFirstLetterOfEachWord(
                        recordDetail.Vendor.Name1 +
                          " " +
                          recordDetail.Vendor.Name2
                      )
                    : "-"}
                </div>
                <div className="col-4">
                  <label className="header">Vendor ID</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.VendorId
                    ? recordDetail.Vendor.VendorId
                    : "-"}
                </div>
                <div className="col-4">
                  <label className="header">Vendor Number</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.VendorNumber
                    ? recordDetail.Vendor.VendorNumber
                    : "-"}
                </div>
                <div className="col-4">
                  <label className="header">Email</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.Email
                    ? recordDetail.Vendor.Email
                    : "-"}
                </div>
                <div className="col-4">
                  <label className="header">Phone</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.Phone
                    ? this.props.global.phoneNumberFormat(
                        recordDetail.Vendor.Phone
                      )
                    : "-"}
                </div>
                <div className="col-4">
                  <label className="header">Billing Address</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.Address1
                    ? (recordDetail.Vendor.Address1
                        ? recordDetail.Vendor.Address1 + " "
                        : "") +
                      (recordDetail.Vendor.Address2
                        ? recordDetail.Vendor.Address2 + " "
                        : "") +
                      (recordDetail.Vendor.City
                        ? recordDetail.Vendor.City + " "
                        : "") +
                      (recordDetail.Vendor.State
                        ? recordDetail.Vendor.State + " "
                        : "") +
                      (recordDetail.Vendor.Zip
                        ? recordDetail.Vendor.Zip + " "
                        : "") +
                      (recordDetail.Vendor.Country
                        ? recordDetail.Vendor.Country
                        : "")
                    : "-"}
                </div>
                <div className="col-4">
                  <label className="header">Billing Address</label>
                </div>
                <div className="col-8">
                  {recordDetail &&
                  recordDetail.Vendor &&
                  recordDetail.Vendor.remitAddress1
                    ? (recordDetail.Vendor.remitAddress1
                        ? recordDetail.Vendor.remitAddress1 + " "
                        : "") +
                      (recordDetail.Vendor.remitAddress2
                        ? recordDetail.Vendor.remitAddress2 + " "
                        : "") +
                      (recordDetail.Vendor.remitCity
                        ? recordDetail.Vendor.remitCity + " "
                        : "") +
                      (recordDetail.Vendor.remitState
                        ? recordDetail.Vendor.remitState + " "
                        : "") +
                      (recordDetail.Vendor.remitZip
                        ? recordDetail.Vendor.remitZip + " "
                        : "") +
                      (recordDetail.Vendor.remitCountry
                        ? recordDetail.Vendor.remitCountry
                        : "")
                    : "-"}
                </div>
              </div>

              <h5 className="header mb-3">Processing Information</h5>
              <div className="row mb-4">
                <div className="col-4">
                  <label className="header">Payment ID</label>
                </div>
                <div className="col-8">
                  {recordDetail && recordDetail.PaymentId !== null
                    ? recordDetail.PaymentId
                    : "-"}
                </div>

                <div className="col-4">
                  <label className="header">Payment Status</label>
                </div>
                <div className="col-8">
                  {recordDetail && recordDetail.PaymentStatus
                    ? this.props.global.capitalizeFirstLetter(
                        recordDetail.PaymentStatus
                      )
                    : "-"}
                </div>

                <div className="col-4">
                  <label className="header">Source</label>
                </div>
                <div className="col-8">
                  {recordDetail && recordDetail.Source
                    ? this.props.global.capitalizeFirstLetter(
                        recordDetail.Source
                      )
                    : "-"}
                </div>
              </div>

              {recordDetail &&
                recordDetail.Events &&
                recordDetail.Events.length > 0 && (
                  <>
                    <h5 className="header mb-3">Transaction Event History</h5>
                    <div className="timeLineRecordContainer">
                      {recordDetail.Events.map((record, i) => (
                        <div key={i} className="timeLineRecordItem">
                          <div className="timeLineRecord">&nbsp;</div>
                          <div className="timeLineRecordText">
                            <b>{record.TransEvent}</b>
                            <br />
                            {this.props.global.stringDateFormat(
                              record.EventTime
                            )}{" "}
                            <span className="grey">
                              {this.props.global.stringTimeFormat(
                                record.EventTime
                              )}{" "}
                              {"(" + this.props.global.getTimeZone("v1") + ")"}
                            </span>
                          </div>
                          <br />
                        </div>
                      ))}
                    </div>
                  </>
                )}
            </div>
          </RightPanel>

          <ParentFilterPanel
            report={"payouts"}
            vendorNumber={this.props.match.params.id}
            billNumber={
              this.props.match.params.billNumber
                ? decodeURIComponent(this.props.match.params.billNumber)
                : null
            }
            batchId={this.props.match.params.idBatch}
          />

          <TopBar>
            <MainTopBarMenu />
            <div className="top-bar-sub">
              {this.state.currentVendor ? (
                <VendorsLinks
                  gobackLink={
                    "/" +
                    this.props.global.getURLEntry() +
                    "/vendor/" +
                    this.state.currentVendor.VendorId
                  }
                  gobackText="Vendor Overview"
                  history={this.props.history}
                  goback={true}
                  selected="transactions"
                  id={this.state.currentVendor.VendorId}
                />
              ) : (
                <>
                  {!this.props.match.params.id &&
                    !this.props.match.params.billNumber &&
                    !this.props.match.params.idBatch && (
                      <MoneyOutLinks
                        gobackText="Go back"
                        history={this.props.history}
                        goback={false}
                        selected="transactions"
                      />
                    )}

                  {this.props.match.params.idBatch && (
                    <MoneyOutLinks
                      gobackText="Go back"
                      history={this.props.history}
                      goback={true}
                      selected="batches"
                    />
                  )}
                </>
              )}
              {this.props.match.params.billNumber && (
                <MoneyOutLinks
                  gobackText="Go back"
                  history={this.props.history}
                  goback={true}
                  selected="transactions"
                />
              )}
            </div>
          </TopBar>

          <div className="mt-body4">
            <MainBar
              reportNameComponent={this.getNameReport}
              dataQAName="TransactionsMoneyOut"
              btnNewName="Add Bill"
              newLink={
                "/" + PayabliStorageManager.getEntryName() + "/bills/new"
              }
              addButtonLink={!this.props.match.params.idBatch}
              showHideFilters={this.showHideFilters}
              selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
              handleShowColumn={(e) => this.handleShowColumn(e)}
              totalRecords={this.props.reports.totalRecords}
              getHeaders={this.props.reports.getHeaders}
              headers={this.props.reports.headers}
              refreshView={this.clearFilters}
              buttonExport={true}
              searchBar={false}
              exportFile={this.exportFile}
              withTotal={true}
              reportOut={true}
              totalAmount={
                this.props.reports.totalAmount
                  ? this.props.global.numberWithCommas(
                      this.props.reports.totalAmount.toFixed(2)
                    )
                  : "0.00"
              }
              flagItemAllColumn={this.state.flagItemAllColumn}
            />

            {!this.props.match.params.idBatch && (
              <div className="statistic-reports">
                <div className='item' onClick={(e) => this.handleQuickFilter('11')}>
                    <div className='row'>
                        <div className='col-8 text-right column'>
                            <p>Authorized</p>
                            ${summary && summary.totalAuthorizedAmount ? this.props.global.numberWithCommas(summary.totalAuthorizedAmount.toFixed(2)): "0.00"}
                        </div>
                        <div className='col-4 column'>
                          <span className="bg-secondary">{summary && summary.totalAuthorized ? summary.totalAuthorized : "0"}</span>
                        </div>
                    </div>
                </div>
                <div className='item' onClick={(e) => this.handleQuickFilter('1')}>
                    <div className='row'>
                        <div className='col-8 text-right column'>
                            <p>Captured</p>
                            ${summary && summary.totalCapturedAmount ? this.props.global.numberWithCommas(summary.totalCapturedAmount.toFixed(2)): "0.00"}
                        </div>
                        <div className='col-4 column'>
                          <span className="bg-secondary">{summary && summary.totalCaptured ? summary.totalCaptured : "0"}</span>
                        </div>
                    </div>
                </div>
                <div className='item' onClick={(e) => this.handleQuickFilter('2')}>
                    <div className='row'>
                        <div className='col-8 text-right column'>
                            <p>Processing</p>
                            ${summary && summary.totalProcessingAmount ? this.props.global.numberWithCommas(summary.totalProcessingAmount.toFixed(2)): "0.00"}
                        </div>
                        <div className='col-4 column'>
                          <span className="bg-warning">{summary && summary.totalProcessing ? summary.totalProcessing : "0"}</span>
                        </div>
                    </div>
                </div>
                <div className='item' onClick={(e) => this.handleQuickFilter('3')}>
                    <div className='row'>
                        <div className='col-8 text-right column'>
                            <p>Processed</p>
                            ${summary && summary.totalOpenAmount ? this.props.global.numberWithCommas(summary.totalOpenAmount.toFixed(2)): "0.00"}
                        </div>
                        <div className='col-4 column'>
                          <span className="bg-warning">{summary && summary.totalOpen ? summary.totalOpen : "0"}</span>
                        </div>
                    </div>
                </div>
                <div className='item' onClick={(e) => this.handleQuickFilter('5')}>
                    <div className='row'>
                        <div className='col-8 text-right column'>
                            <p>Paid</p>
                            ${summary && summary.totalPaidAmount ? this.props.global.numberWithCommas(summary.totalPaidAmount.toFixed(2)): "0.00"}
                        </div>
                        <div className='col-4 column'>
                          <span>{summary && summary.totalPaid ? summary.totalPaid : "0"}</span>
                        </div>
                    </div>
                </div>
                <div className='item' onClick={(e) => this.handleQuickFilter("0|-1", "status(in)")}>
                    <div className='row'>
                        <div className='col-8 text-right column'>
                            <p>Cancelled</p>
                            ${summary && summary.totalCanceledAmount ? this.props.global.numberWithCommas(summary.totalCanceledAmount.toFixed(2)): "0.00"}
                        </div>
                        <div className='col-4 column'>
                          <span className="bg-danger">{summary && summary.totalCanceled ? summary.totalCanceled : "0"}</span>
                        </div>
                    </div>
                </div>  

              </div>
            )}
            <div className="report-container">
              <table className="table table-hover table-striped">
                <thead>
                  <tr>
                    {this.props.reports.getHeaders.map(
                      (record, i) =>
                        (Object.entries(this.getMappingFields())
                          .flat(1)
                          .includes(record[0]) &&
                          record[1].display && (
                            <th
                              key={i}
                              scope="col"
                              className={`${record[1].class} sortby-parent`}
                            >
                              {record[1].label}
                              <BiSortAlt2
                                className="cursorPointer sort-icon"
                                onClick={(e) => this.sortBy(e, record)}
                              />
                            </th>
                          )) ||
                        (record[1].display && (
                          <th key={i} scope="col" className={record[1].class}>
                            {record[1].label}
                          </th>
                        ))
                    )}
                    <th className="text-center sticky-row" scope="col">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.reports.records.map((record, i) => (
                    <tr
                      key={i}
                      onMouseUp={(e) => this.props.global.setCoordClickUp(e)}
                      onMouseDown={(e) =>
                        this.props.global.setCoordClickDown(e)
                      }
                      className="cursorPointer"
                      onClick={(e) => this.viewRecordDetails(i, e)}
                    >
                      {this.props.reports.headers.VendorName &&
                        this.props.reports.headers.VendorName.display && (
                          <td className="avatarTd">
                            <NameAvatar
                              text={
                                record && record.Vendor.Name1
                                  ? record.Vendor.Name1 +
                                    " " +
                                    record.Vendor.Name2
                                  : "-"
                              }
                            />{" "}
                            {record.Vendor && record.Vendor.Name1
                              ? this.props.global.capitalizeFirstLetterOfEachWord(
                                  record.Vendor.Name1
                                )
                              : "-"}{" "}
                            {record.Vendor && record.Vendor.Name2
                              ? this.props.global.capitalizeFirstLetterOfEachWord(
                                  record.Vendor.Name2
                                )
                              : ""}
                          </td>
                        )}
                      {this.props.reports.headers.PaymentId &&
                        this.props.reports.headers.PaymentId.display && (
                          <td>
                            {record && record.PaymentId
                              ? record.PaymentId
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.LotNumber &&
                        this.props.reports.headers.LotNumber.display && (
                          <td>
                            {record && record.LotNumber
                              ? record.LotNumber
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.externalPaypointID &&
                        this.props.reports.headers.externalPaypointID
                          .display && (
                          <td>
                            {record?.externalPaypointID
                              ? record.externalPaypointID
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.BatchNumber &&
                        this.props.reports.headers.BatchNumber.display && (
                          <td>
                            {record && record.BatchNumber
                              ? record.BatchNumber
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.VendorId &&
                        this.props.reports.headers.VendorId.display && (
                          <td>
                            {record.Vendor && record.Vendor.VendorId
                              ? record.Vendor.VendorId
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.CustomerVendorAccount &&
                        this.props.reports.headers.CustomerVendorAccount
                          .display && (
                          <td>
                            {record.Vendor &&
                            record.Vendor.customerVendorAccount
                              ? record.Vendor.customerVendorAccount
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.VendorNumber &&
                        this.props.reports.headers.VendorNumber.display && (
                          <td>
                            {record.Vendor && record.Vendor.VendorNumber
                              ? record.Vendor.VendorNumber
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.Ein &&
                        this.props.reports.headers.Ein.display && (
                          <td>
                            {record.Vendor && record.Vendor.EIN
                              ? record.Vendor.EIN
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.VendorEmail &&
                        this.props.reports.headers.VendorEmail.display && (
                          <td>
                            {record.Vendor && record.Vendor.Email
                              ? record.Vendor.Email
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.VendorPhone &&
                        this.props.reports.headers.VendorPhone.display && (
                          <td>
                            {record.Vendor && record.Vendor.Phone
                              ? record.Vendor.Phone
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.BillingAddress &&
                        this.props.reports.headers.BillingAddress.display && (
                          <td>
                            {record.Vendor && record.Vendor.Address1
                              ? (record.Vendor.Address1
                                  ? record.Vendor.Address1 + " "
                                  : "") +
                                (record.Vendor.Address2
                                  ? record.Vendor.Address2 + " "
                                  : "") +
                                (record.Vendor.City
                                  ? record.Vendor.City + " "
                                  : "") +
                                (record.Vendor.State
                                  ? record.Vendor.State + " "
                                  : "") +
                                (record.Vendor.Zip
                                  ? record.Vendor.Zip + " "
                                  : "") +
                                (record.Vendor.Country
                                  ? record.Vendor.Country
                                  : "")
                              : "-"}{" "}
                          </td>
                        )}
                      {this.props.reports.headers.RemitAddress &&
                        this.props.reports.headers.RemitAddress.display && (
                          <td>
                            {record.Vendor && record.Vendor.remitAddress1
                              ? (record.Vendor.remitAddress1
                                  ? record.Vendor.remitAddress1 + " "
                                  : "") +
                                (record.Vendor.remitAddress2
                                  ? record.Vendor.remitAddress2 + " "
                                  : "") +
                                (record.Vendor.remitCity
                                  ? record.Vendor.remitCity + " "
                                  : "") +
                                (record.Vendor.remitState
                                  ? record.Vendor.remitState + " "
                                  : "") +
                                (record.Vendor.remitZip
                                  ? record.Vendor.remitZip + " "
                                  : "") +
                                (record.Vendor.remitCountry
                                  ? record.Vendor.remitCountry
                                  : "")
                              : "-"}{" "}
                          </td>
                        )}
                      {this.props.reports.headers.BillNumber &&
                        this.props.reports.headers.BillNumber.display && (
                          <td style={{ color: "#10A0E3" }}>
                            {record.Bills && (
                              <>
                                {record.Bills.length > 1 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip>
                                        {this.getBillsNumbers(record.Bills)}
                                      </Tooltip>
                                    }
                                  >
                                    <span>
                                      <BiDetail
                                        className="label-info-icon in-input"
                                        style={{ fontSize: "15px" }}
                                      />
                                      Multiple Bills
                                    </span>
                                  </OverlayTrigger>
                                ) : (
                                  record.Bills.length === 1 && (
                                    <a
                                      href={
                                        "/" +
                                        this.props.global.getURLEntry() +
                                        "/report/bills/bill/" +
                                        encodeURIComponent(
                                          record.Bills[0].invoiceNumber
                                        )
                                      }
                                    >
                                      {record.Bills[0].invoiceNumber}
                                    </a>
                                  )
                                )}
                              </>
                            )}
                          </td>
                        )}
                      {this.props.reports.headers.Date &&
                        this.props.reports.headers.Date.display && (
                          <td>
                            {record && record.CreatedAt
                              ? this.props.global.stringDateFormatV3(
                                  record.CreatedAt
                                )
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.Time &&
                        this.props.reports.headers.Time.display && (
                          <td>
                            {record && record.CreatedAt
                              ? this.props.global.stringTimeFormat(
                                  record.CreatedAt
                                )
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.Type &&
                        this.props.reports.headers.Type.display && (
                          <td className="text-center">
                            {record?.PaymentMethod ? (
                              (record.PaymentMethod.toString()?.toLowerCase() === 'check' && record.CheckData) ? (
                                <FileViewer fileData={record.CheckData} fileType={record.CheckData.ftype} />
                              ) : (
                                this.props.global.getPaymethodImg(
                                  record.PaymentMethod
                                )
                              )
                            ) : (
                              this.props.global.getPaymethodImg("timer")
                            )}
                          </td>
                        )}
                      {this.props.reports.headers.Status &&
                        this.props.reports.headers.Status.display && (
                          <td data-qaid={"transactionMoneyOutRow-" + i}>
                            {this.props.global.getMoneyOutTransStatus(
                                  record.Status
                                )}
                          </td>
                        )}
                      {this.props.reports.headers.LastEvent &&
                        this.props.reports.headers.LastEvent.display && (
                          <td>
                            {record &&
                            record.Events &&
                            Array.isArray(record.Events) &&
                            record.Events.at(-1)
                              ? record.Events.at(-1).TransEvent
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.NetAmount &&
                        this.props.reports.headers.NetAmount.display && (
                          <td className="text-right">
                            $
                            {record && record.NetAmount
                              ? this.props.global.numberWithCommas(
                                  record.NetAmount.toFixed(2)
                                )
                              : "0.00"}
                          </td>
                        )}
                      {this.props.reports.headers.FeeAmount &&
                        this.props.reports.headers.FeeAmount.display && (
                          <td className="text-right">
                            $
                            {record && record.FeeAmount
                              ? this.props.global.numberWithCommas(
                                  record.FeeAmount.toFixed(2)
                                )
                              : "0.00"}
                          </td>
                        )}
                      {this.props.reports.headers.TotalAmount &&
                        this.props.reports.headers.TotalAmount.display && (
                          <td className="text-right">
                            $
                            {record && record.TotalAmount
                              ? this.props.global.numberWithCommas(
                                  record.TotalAmount.toFixed(2)
                                )
                              : "0.00"}
                          </td>
                        )}
                      {this.props.reports.headers.TransId &&
                        this.props.reports.headers.TransId.display && (
                          <td>
                            {record && record.PaymentId
                              ? record.PaymentId
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.Source &&
                        this.props.reports.headers.Source.display && (
                          <td>
                            {record.Source
                              ? this.props.global.capitalizeFirstLetter(
                                  record.Source
                                )
                              : "-"}
                          </td>
                        )}
                      {this.props.reports.headers.Notes &&
                        this.props.reports.headers.Notes.display && (
                          <td className="text-center">
                            {record && record.Comments ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>{record.Comments}</Tooltip>}
                              >
                                <BiNotepad className="label-info-icon in-input" />
                              </OverlayTrigger>
                            ) : (
                              "-"
                            )}
                          </td>
                        )}
                      {this.props.reports.headers.LastUpdatedOn &&
                        this.props.reports.headers.LastUpdatedOn.display && (
                          <td>
                            {record && record.LastUpdated
                              ? this.props.global.stringDateFormatV3(
                                  record.LastUpdated
                                )
                              : "-"}
                          </td>
                        )}
                      <td className="text-center sticky-row">
                        <DropdownButton
                          menuAlign="right"
                          title={<BiDotsVerticalRounded />}
                          data-qaid="transactionsMoneyOutActions"
                          id="actionsMenuButton"
                          size="sm"
                          variant="default"
                        >
                          <Dropdown.Item
                            onClick={(e) => this.viewRecordDetails(i)}
                            data-qaid="quickViewTransactionMoneyOutLink"
                          >
                            <div>Quick View</div>
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to={
                              "/" +
                              this.props.global.getURLEntry() +
                              "/vendor/" +
                              (record && record.Vendor
                                ? record.Vendor.VendorId
                                : "")
                            }
                            data-qaid="viewVendorTransactionMoneyOutLink"
                          >
                            View Vendor
                          </Dropdown.Item>
                          {record && record.Status === 1 && record.PaymentId && record.PaymentMethod?.toLowerCase() === "vcard" && (
                              <Dropdown.Item onClick={() => this.sendCardLinkModal(record.PaymentId, record.Status)}>
                                Send VCard Link
                              </Dropdown.Item>
                          )}
                          {record && record.Status === 11 && record.PaymentId && record.PaymentMethod?.toLowerCase() === "vcard" && (
                              <Dropdown.Item onClick={() => this.sendCardLinkModal(record.PaymentId, record.Status)}>
                                Send VCard Link
                              </Dropdown.Item>
                          )}
                          {(record && (record.Status === 1 || record.Status === 11))&& (
                            <Dropdown.Item
                              onClick={(e) =>
                                this.cancelPayment(record.PaymentId)
                              }
                              data-qaid="cancelPaymentTransactionMoneyOutLink"
                            >
                              Cancel Payment
                            </Dropdown.Item>
                          )}
                          {record && record.Status === 11 && (
                            <Dropdown.Item
                              onClick={(e) =>
                                this.captureTransaction(
                                  record.PaymentId,
                                  record.NetAmount
                                )
                              }
                            >
                              Capture
                            </Dropdown.Item>
                          )}
                        </DropdownButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {this.props.reports.records.length < 1 && (
                <RecordsNotFound
                  message="No transactions yet"
                  description={
                    <span>You can track transactions details here.</span>
                  }
                />
              )}
            </div>
            <ReportPaginator report="payouts" />
          </div>
          <ToastContainer transition={Bounce} />
        </div>
      </Layout>
    );
  }
}

export { TransactionsReportMoneyOut };
